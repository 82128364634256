<template>
  <Transition name="list">
    <LoadingToast
      v-if="loading === true"
      title="Page Loading"
      description="Please wait..."
    />
  </Transition>
</template>

<script setup lang="ts">
// data
const router = useRouter()
const loading = ref(false)

// logic
router.beforeEach(() => (loading.value = true))
router.afterEach(() => (loading.value = false))
</script>
